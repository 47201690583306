@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
.service-main-container {
    margin-top: 5rem;
    padding: 5rem 0;
  }
  .work-container-subdiv>img{
    height: 90px;
   
  }
  
  .service-main-container .main-heading {
    margin: 3rem 0;
  }
  
  .work-container-subdiv {
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
    display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  }
  
  .work-container-subdiv:hover {
    background-color: var(--bgcolor);
    cursor: pointer;
  }
  
  .work-container-subdiv:hover > .fontawesome-style {
    background-color: #fff;
  }
  
  .work-container-subdiv .main-hero-para {
    width: 100%;
  }
  
  .service-main-container .work-container-subdiv .fontawesome-style {
    text-align: left;
    margin: 2rem 0;
  }
  
  @media (min-width: 1400px) {
    .service-container .col-xxl-4 {
      flex: 0 0 auto;
      width: 30% !important;
      margin: auto;
    }
  }
  
  @media (min-width: 992px) {
    .service-container .col-lg-4 {
      flex: 0 0 auto;
      width: 30% !important;
      margin: 2.5rem auto;
      margin-left: 23px;
    }
  }