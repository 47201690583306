*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
}
form > input{
    
        width: 100%;
        height: 35px;
        padding: 7px;
        outline: none;
        border-radius: 5px;
        border: 1px solid rgb(220, 220, 220);
  
}
input:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
  textarea {
    
    min-width: 65%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  }
  textarea:focus {
    border: 2px solid rgba(0, 206, 158, 1);
  }
 .Submitbutton{
  margin-top: 20px;
width: 140px;
height: 45px;
border-radius: 10px;
border: none;
  outline: none;
  color: #ffffff;
background-color:#00b7ff;
 }
  .rowitems{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .address{
    width: 682px;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
 
  }
  .phone{

    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    box-sizing: border-box;
  }
  .phone>h2{
    margin: 0;
    padding: 0;
  }
  .email{
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    box-sizing: border-box;
  }
  .address>p{
    width: 50%;
    margin: 0;
    padding: 0%;
    
  }
  .contact_info{
    width: 100%;
    margin-top: 100px;
  }
  .contact_info>h1{
    text-align: center;
  }
  .contactform>form{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .location{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }


  /* ----------------------------icons---------------------- */
  .address>h1>svg{
    font-size: 28px;
    margin-top: 35px;
  }

  iframe {
    border: 0;
    width: 100%;
}