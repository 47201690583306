.container{
display: grid;
justify-content: center;
max-width: 100%;
overflow-x: hidden;
box-sizing: border-box;
}
.grid-four-column{
    grid-template-columns: 1fr 1.2fr .5fr .8fr ;
    
 }
 footer{
    background-color: #00b7ff;
 }

 input[type="submit"]{
    max-width: 16rem;
    margin-top: 2rem;
    background-color: black;
    color: white;
    padding: 1.4rem 2.2rem;
    border-style: solid;
    border-width: .1rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    cursor: pointer;
}
input, textarea{
    max-width: 50rem;
    color: black;
    padding: 1.6rem , 2.4rem;
    border: 1px solid black;
    
   
}
.footer-social{
    padding-left: 20px;
}
.footer-social--icons{
    display: flex;
    gap: 20px;
    
}
.footer-social--icons> div{
    padding: 15px;
      border-radius: 50%;
      border: 2px solid white;
}
.footer-contact{
    padding-left: 75px;
}
.icons{
    font-size: 1.4rem;
    position: relative;
    cursor: pointer;
}
@media (max-width:991px) {
    .grid-four-column{
        grid-template-columns:repeat(2, 1fr);
      
     }
}
@media (max-width:589px) {
    .grid-four-column{
        grid-template-columns:repeat(1, 1fr);
      
     }
}
@media (max-width:600px) {
    
}