.nav{
    display: flex;
justify-content: space-around;
align-items: center;
position: fixed;
left: 0;
top: 0;
width:100%;
z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}
.services_title{
    margin-top: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    width: 100%;
    background-color: #0000009e;
    text-align: center;
    color: white;

}
.services_images_container{
display: flex;
justify-content: center;
align-items: center;

}
.services_desc{
   
    height: 110px;
    width: 75%;
   
    box-sizing: border-box;
    margin: auto;
    text-align: center;
}

.miningprocess > img{
    width: 100%;
    height: 90%;
    margin: auto;
}

